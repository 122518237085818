import { Typography } from "@material-tailwind/react";
import { useSelector } from "react-redux";
import Table from "./index";
import { Link } from "react-router-dom";
import { useMemo } from "react";
import { useParams } from "react-router";
import DefaultSubstituteAvatar from "../../assets/img/dark_mode/default-substitutue-avatar.svg";

const PlayerStats = ({ players, league, teamId, playerKeyword }) => {
  let { leagueId, matchId } = useParams();
  const displaySubstitutes = league?.displaySubstitutes;
  const displayPosition = league?.displayPosition;
  const displayJerseyNumber = league?.displayJerseyNumber;
  const displayAttempts3 = league?.displayAttempts3;
  const displayAttempts2 = league?.displayAttempts2;
  const displayAttempts1 = league?.displayAttempts1;
  const displayBlocks = league?.displayBlocks;
  const displayRebounds = league?.displayRebounds;
  const displayAssists = league?.displayAssists;
  const displayFouls = league?.displayFouls;
  const displaySteals = league?.displaySteals;
  const displayTurnovers = league?.displayTurnovers;

  const teams = useSelector((state) => state.home.teams);
  const logs = useSelector(state => state.home.logs).filter(log => log.matchId == matchId)
  const columns = [
    {
      label: "Player",
      accessor: "firstName",
      fixed: true,
      getValue: (row) => (
        <div className="flex items-center">
          {
            row.isSubstitute == false ?
              <>
                <Link to={`/league/${leagueId}/player/${row.id}`}>
                  <img
                    src={row.avatar}
                    alt=""
                    className="h-8 w-8 mr-4 rounded-full border border-gray-500"
                  />
                </Link>
                <Link
                  to={`/league/${leagueId}/player/${row.id}`}
                  className="hover:underline"
                >
                  {row.firstName} {row.lastName}
                </Link>
              </>
              :
              <>
                <img
                  src={DefaultSubstituteAvatar}
                  alt=""
                  className="h-8 w-8 mr-4 rounded-full border border-gray-500"
                />
                {row.firstName} {row.lastName}
              </>
          }
        </div>
      ),
    },
    {
      label: "Team",
      accessor: "teamName",
      getValue: (row) =>
        row.team && (
          <div className="flex items-center">
            <Link to={`/league/${leagueId}/team/${row.teamId}`}>
              <img
                src={row.team?.logo}
                alt=""
                className="w-8 h-8 mr-2 rounded-full border border-gray-500"
              />
            </Link>
            <Link to={`/league/${leagueId}/team/${row.teamId}`} className="hover:underline">
              {row.teamName}
            </Link>
          </div>
        ),
    },
    displayJerseyNumber && {
      label: "Jersey Number",
      accessor: "jerseyNumber",
      getValue: (row) => (
        <Typography variant="small" className="font-normal">
          {row.jerseyNumber}
        </Typography>
      ),
    },
    displayPosition && {
      label: "Position",
      accessor: "position",
      condition: displayPosition,
      getValue: (row) => (
        <Typography variant="small" className="font-normal">
          {row.position}
        </Typography>
      ),
    },
    {
      label: "PTS",
      accessor: "totalPoints",
      getValue: (row) => (
        <Typography variant="small" className="font-normal">
          {row.totalPoints}
        </Typography>
      ),
    },
    {
      label: "3PM",
      accessor: "totalPoints3",
      getValue: (row) => (
        <Typography variant="small" className="font-normal">
          {row.totalPoints3}
        </Typography>
      ),
    },
    displayAttempts3 && {
      label: "3PA",
      accessor: "attempts3",
      condition: displayAttempts3,
      getValue: (row) => (
        <Typography variant="small" className="font-normal">
          {row.attempts3}
        </Typography>
      ),
    },
    displayAttempts3 && {
      label: "3P%",
      accessor: "3p%",
      condition: displayAttempts3,
      getValue: (row) => (
        <Typography variant="small" className="font-normal">
          {isNaN((row.totalPoints3 / row.attempts3) * 100)
            ? 0
            : ((row.totalPoints3 / row.attempts3) * 100).toFixed(2)}
        </Typography>
      ),
    },
    {
      label: "FGM",
      accessor: "totalPoints2",
      getValue: (row) => (
        <Typography variant="small" className="font-normal">
          {row.totalPoints2}
        </Typography>
      ),
    },
    displayAttempts2 && {
      label: "FGA",
      getValue: (row) => (
        <Typography variant="small" className="font-normal">
          {row.attempts2}
        </Typography>
      ),
    },
    displayAttempts2 && {
      label: "FG%",
      accessor: "fg%",
      getValue: (row) => (
        <Typography variant="small" className="font-normal">
          {isNaN((row.totalPoints2 / row.attempts2) * 100)
            ? 0
            : ((row.totalPoints2 / row.attempts2) * 100).toFixed(2)}
        </Typography>
      ),
    },
    {
      label: "FTM",
      accessor: "totalPoints1",
      getValue: (row) => (
        <Typography variant="small" className="font-normal">
          {row.totalPoints1}
        </Typography>
      ),
    },
    displayAttempts1 && {
      label: "FTA",
      accessor: "attempts1",
      condition: displayAttempts1,
      getValue: (row) => (
        <Typography variant="small" className="font-normal">
          {row.attempts1}
        </Typography>
      ),
    },
    displayAttempts1 && {
      label: "FT%",
      condition: displayAttempts1,
      getValue: (row) => (
        <Typography variant="small" className="font-normal">
          {isNaN((row.totalPoints1 / row.attempts1) * 100)
            ? 0
            : ((row.totalPoints1 / row.attempts1) * 100).toFixed(2)}
        </Typography>
      ),
    },
    displayBlocks && {
      label: "BLK",
      accessor: "blocks",
      condition: displayBlocks,
      getValue: (row) => (
        <Typography variant="small" className="font-normal">
          {row.blocks}
        </Typography>
      ),
    },
    displayRebounds && {
      label: "REB",
      accessor: "rebounds",
      condition: displayRebounds,
      getValue: (row) => (
        <Typography variant="small" className="font-normal">
          {row.rebounds}
        </Typography>
      ),
    },
    displayAssists && {
      label: "AST",
      accessor: "assists",
      condition: displayAssists,
      getValue: (row) => (
        <Typography variant="small" className="font-normal">
          {row.assists}
        </Typography>
      ),
    },
    displayFouls && {
      label: "PF",
      accessor: "fouls",
      condition: displayFouls,
      getValue: (row) => (
        <Typography variant="small" className="font-normal">
          {row.fouls}
        </Typography>
      ),
    },
    displaySteals && {
      label: "STL",
      condition: displaySteals,
      accessor: "steals",
      getValue: (row) => (
        <Typography variant="small" className="font-normal">
          {row.steals}
        </Typography>
      ),
    },
    displayTurnovers && {
      label: "TOV",
      accessor: "turnovers",
      condition: displayTurnovers,
      getValue: (row) => (
        <Typography variant="small" className="font-normal">
          {row.turnovers}
        </Typography>
      ),
    },
    {
      label: "GP",
      accessor: "gp",
      getValue: (row) => (
        <Typography variant="small" className="font-normal">
          {row.gp}
        </Typography>
      ),
    },
    {
      label: "PPG",
      accessor: "ppg",
      getValue: (row) => (
        <Typography variant="small" className="font-normal">
          {row.ppg}
        </Typography>
      ),
    },
  ].filter(Boolean);

  const data = useMemo(() => {
    let mappedData = players
      .sort((a, b) => b.points - a.points)
      .map((player) => {
        let totalPoints = 0
        let totalPoints3 = 0
        let attempts3 = 0;
        let totalPoints2 = 0
        let attempts2 = 0;
        let totalPoints1 = 0
        let attempts1 = 0;
        let blocks = 0;
        let rebounds = 0;
        let assists = 0;
        let fouls = 0;
        let steals = 0;
        let turnovers = 0;

        for (const log of logs) {
          if (log.playerId == player.id) {
            switch (log.event) {
              case '+3 Pointer':
                totalPoints += 3;
                totalPoints3 += 1
                totalPoints2 += 1;
                attempts3 += 1
                attempts2 += 1
                break;
              case '+2 Pointer':
                totalPoints += 2;
                totalPoints2 += 1
                attempts2 += 1
                break;
              case '+1 Pointer':
                totalPoints += 1;
                totalPoints1 += 1
                attempts1 += 1
                break;
              case '+3 Attempt':
                attempts3 += 1;
                attempts2 += 1;
                break;
              case '+2 Attempt':
                attempts2 += 1
                break;
              case '+1 Attempt':
                attempts1 += 1
                break;
              case 'Rebound':
                rebounds += 1
                break;
              case 'Turnover':
                turnovers += 1
                break;
              case 'Foul':
                fouls += 1
                break;
              case 'Block':
                blocks += 1
                break;
              case 'Assist':
                assists += 1
                break;
            }
          }
        }

        return {
          totalPoints: totalPoints,
          totalPoints1: totalPoints1,
          totalPoints2: totalPoints2,
          totalPoints3: totalPoints3,
          attempts1: attempts1,
          attempts2: attempts2,
          attempts3: attempts3,
          "3p%": isNaN(
            (totalPoints3 /
              attempts3) *
            100
          )
            ? 0
            : (
              (totalPoints3 /
                attempts3) *
              100
            ).toFixed(2),
          "fg%": isNaN(
            (totalPoints2 /
              attempts2) *
            100
          )
            ? 0
            : (
              (totalPoints2 /
                attempts2) *
              100
            ).toFixed(2),
          "ft%": isNaN(
            (totalPoints1 /
              attempts1) *
            100
          )
            ? 0
            : (
              (totalPoints1 /
                attempts1) *
              100
            ).toFixed(2),
          blocks: blocks,
          rebounds: rebounds,
          assists: assists,
          fouls: fouls,
          steals: steals,
          turnovers: turnovers,
          position: player.position,
          userId: player.userId,
          id: player.id,
          jerseyNumber: player.jerseyNumber,
          firstName: player.firstName,
          lastName: player.lastName,
          avatar: player.avatar ? player.avatar : DefaultSubstituteAvatar,
          isSubstitute: player.isSubstitute,
          team: teams.find((team) => team.id == player.teamId),
          teamName: teams.find((team) => team.id == player.teamId)?.name,
          teamId: player.teamId,
          gp: 0,
          ppg: 0,
        };
      });

    // if (displaySubstitutes) {
    //   mappedData = mappedData.concat(
    //     substitutes
    //       .sort((a, b) => b.points - a.points)
    //       .map((sub) => {
    //         return {
    //           firstName: sub.firstName,
    //           lastName: sub.lastName,
    //           jerseyNumber: sub.jerseyNumber,
    //           position: sub.position,
    //           totalPoints: sub.totalPoints,
    //           totalPoints3: sub.totalPoints3,
    //           totalPoints2: sub.totalPoints2,
    //           totalPoints1: sub.totalPoints1,
    //           attempts3: sub.attempts3,
    //           attempts2: sub.attempts2,
    //           attempts1: sub.attempts1,
    //           "3p%":
    //             sub.match.isNew &&
    //               isNaN((sub.totalPoints3 / sub.attempts3) * 100)
    //               ? 0
    //               : ((sub.totalPoints3 / sub.attempts3) * 100).toFixed(2),
    //           "fg%":
    //             sub.match.isNew &&
    //               isNaN((sub.totalPoints2 / sub.attempts2) * 100)
    //               ? 0
    //               : ((sub.totalPoints2 / sub.attempts2) * 100).toFixed(2),
    //           "ft%":
    //             sub.match.isNew &&
    //               isNaN((sub.totalPoints1 / sub.attempts1) * 100)
    //               ? 0
    //               : ((sub.totalPoints1 / sub.attempts1) * 100).toFixed(2),
    //           blocks: sub.blocks,
    //           rebounds: sub.rebounds,
    //           assists: sub.assists,
    //           fouls: sub.fouls,
    //           steals: sub.steals,
    //           turnovers: sub.turnovers,
    //           avatar: DefaultSubstituteAvatar,
    //           team: teams.find((team) => team.id == sub.teamId),
    //           teamId: sub.teamId,
    //           teamName: teams.find((team) => team.id == sub.teamId)?.name,
    //         };
    //       })
    //   );
    // }

    return mappedData.filter((data) => (
      data.firstName + data.lastName
    ).toLowerCase().includes(playerKeyword.toLowerCase()));
  }, [players, displaySubstitutes]);

  return (
    <div className="text-black dark:text-white mt-5 w-full">
      <Table data={data} columns={columns} />
    </div>
  );
};

export default PlayerStats;
